import React from "react"
import Header from "../components/header"
import { Container } from "react-bootstrap"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import ym from "react-yandex-metrika"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

class Production extends React.Component {

  componentDidMount() {
    ym("hit", "/production")
  }

  render() {
    let settings = {
      className: "carousel",
      touchMove: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true
    }

    return (
      <div>
        <Helmet>
          <title>Производство - Исполиния</title>
          <meta name="description"
                content="Все производственные предприятия RBM расположены в Италии, что позволяет с гордостью говорить о выпускаемой продукции «100% Made in Italy»." />
          <meta property="og:title" content="Производство" />
          <meta property="og:description"
                content="Все производственные предприятия RBM расположены в Италии, что позволяет с гордостью говорить о выпускаемой продукции «100% Made in Italy»." />
        </Helmet>
        <Header />
        <main>

          <Container className="mb-5">
            <div className="row m-auto">
              <Slider {...settings}>
                <StaticImage className="col-md m-auto wrap-image"
                             src={"../images/production/solar.png"} alt={""} />
                <StaticImage className="col-md m-auto wrap-image" src={"../images/production/red.jpg"} alt={""} />
                <StaticImage className="col-md m-auto wrap-image" src={"../images/production/production.jpg"} alt={""} />
                <StaticImage className="col-md m-auto wrap-image" src={"../images/production/boxes.jpg"} alt={""} />
                <StaticImage className="col-md m-auto wrap-image" src={"../images/production/conveyor.jpg"} alt={""} />
                <StaticImage className="col-md m-auto wrap-image" src={"../images/production/conveyor2.jpg"} alt={""} />
                <StaticImage className="col-md m-auto wrap-image" src={"../images/production/car.jpg"} alt={""} />
                <StaticImage className="col-md m-auto wrap-image"
                              src={"../images/production/rbm.png"} alt={""} />
              </Slider>
              <div className="col-sm">
                <p><span className="blue">История RBM</span> началась в 1953
                  году, когда в городке Лумедзане в итальянской провинции Брешия семья Боссини основала свое
                  первое производство по выпуску изделий из латуни. Многие десятилетия спустя компания в
                  полной мере сохранила свои традиции стремления к совершенству в технологических процессах, а
                  также независимость, оставшись семейной фирмой.&nbsp;</p><p>Сегодня компания RBM является
                одним из европейских лидеров в производстве компонентов для систем отопления и водоснабжения,
                разрабатывая и предлагая на конкурентном и высокотехнологичном рынке продукцию исключительного
                качества.</p>
              </div>
            </div>


            <p>В состав фирмы входят четыре фабрики различной специализации, а само производство имеет полный цикл: от
              разработки штампов, создаваемых силами собственных инженерных подразделений, до многоэтапного
              производственного
              процесса и послепродажного обслуживания.</p>

            <p>Все предприятия RBM расположены в Италии, что позволяет с гордостью говорить о выпускаемой продукции
              «100%
              Made
              in Italy».</p>
            <p> Полный контроль качества на всех этапах производства стал возможен благодаря слаженной работе команды
              опытных
              специалистов и постоянному технологическому и профессиональному обновлению.
            </p>
          </Container>
        </main>
        <Footer />

      </div>
    )
  }
}

export default Production